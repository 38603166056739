import { Checkbox, Col, Form, Row } from "antd";
import { useContext } from "react";
import { QrConfigContext } from "../context/qr-config";
import ColorPicker from "./color-picker";

const property = "backgroundOptions";

export default function BackgroundConfig() {
  const { config, updateConfig } = useContext(QrConfigContext);
  return (
    <Form labelCol={{ xs: 6, md: 4, lg: 3 }} wrapperCol={{ xs: 24, md: 6 }}>
      <Form.Item label="Color">
        {!(config as any)[property]["transparent"] && (
          <ColorPicker
            value={config[property]}
            onChange={(value) => updateConfig(property, value)}
          />
        )}

        <Row>
          <Col span={24}>
            <Checkbox
              checked={!!(config as any)[property]["transparent"]}
              onChange={(e) =>
                updateConfig(`${property}.transparent`, e.target.checked)
              }
            >
              {" "}
              Transparent
            </Checkbox>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
}
