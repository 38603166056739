import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Form, Input, Radio, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useThrottledCallback } from "use-debounce/lib";
import CountryCodeList from "../data/country-code";
import CurrencyList from "../data/paypal-currency-list";
import { Protocol } from "../data/protocol";
import DataGenerator from "../lib/qr-data-generator";

enum PaypalPaymentType {
  BUY_NOW = "_xclick",
  ADD_TO_CART = "_cart",
  DONATIONS = "_donations",
}
interface Model {
  [Protocol.LINK]?: { url?: string };
  [Protocol.TEXT]?: { text?: string };
  [Protocol.EMAIL]?: { email?: string; subject?: string; message?: string };
  [Protocol.CALL]?: { country_code?: string; number?: string };
  [Protocol.SMS]?: { country_code?: string; number?: string; message?: string };
  [Protocol.BITCOIN]?: {
    address?: string;
    amount?: number;
    item_name?: string;
    message?: string;
  };
  [Protocol.PAYPAL]?: {
    payment_type?: PaypalPaymentType;
    email?: string;
    item_name?: string;
    price?: number;
    currency?: string;
    shipping_charge?: number;
    tax_rate?: number;
  };
}

const ProtocolLabels = [
  { label: "Link", value: Protocol.LINK },
  { label: "E-Mail", value: Protocol.EMAIL },
  { label: "Text", value: Protocol.TEXT },
  { label: "Call", value: Protocol.CALL },
  { label: "SMS", value: Protocol.SMS },
  { label: "Bitcoin", value: Protocol.BITCOIN },
  { label: "PayPal", value: Protocol.PAYPAL },
];
const PaypalPaymentTypeLabel = [
  { label: "Add to Cart", value: PaypalPaymentType.ADD_TO_CART },
  { label: "Buy Now", value: PaypalPaymentType.BUY_NOW },
  { label: "Donations", value: PaypalPaymentType.DONATIONS },
];
interface ContentData extends Model {
  protocol?: Protocol;
}

type ContentProp = {
  onChange: (text?: string) => void;
};

const initialData: ContentData = {
  protocol: Protocol.LINK,
  [Protocol.LINK]: { url: "" },
};

const parseData = (data: ContentData) => {
  const { protocol } = data;
  if (!(protocol && data[protocol])) {
    return null;
  }
  if (protocol === Protocol.LINK) {
    const model = data[protocol]!;
    return DataGenerator[protocol](model.url);
  }
  if (protocol === Protocol.EMAIL) {
    const { email, subject, message } = data[protocol]!;
    return DataGenerator[protocol](email, subject, message);
  }
  if (protocol === Protocol.TEXT) {
    const { text } = data[protocol]!;
    return DataGenerator[protocol](text);
  }
  if (protocol === Protocol.CALL) {
    const { country_code, number } = data[protocol]!;
    return DataGenerator[protocol](number, country_code);
  }
  if (protocol === Protocol.SMS) {
    const { country_code, number, message } = data[protocol]!;
    return DataGenerator[protocol](number, message, country_code);
  }
  if (protocol === Protocol.BITCOIN) {
    const { address, amount, item_name, message } = data[protocol]!;
    return DataGenerator[protocol](address, amount, item_name, message);
  }
  if (protocol === Protocol.PAYPAL) {
    const {
      currency,
      email,
      item_name,
      payment_type,
      price,
      shipping_charge,
      tax_rate,
    } = data[protocol]!;
    return DataGenerator[protocol](
      payment_type,
      email,
      price,
      currency,
      item_name,
      shipping_charge,
      tax_rate
    );
  }
  return null;
};

export default function QrContent(prop: ContentProp) {
  const [data, setData] = useState({ ...initialData });
  const [form] = Form.useForm();

  const onChange = (_: any, values: any) => {
    setData(values);
  };

  const notifyChange = useThrottledCallback((data: ContentData) => {
    const result = parseData(data);
    prop.onChange(result || undefined);
  }, 100);
  useEffect(() => {
    notifyChange(data);
  }, [data, notifyChange]);

  return (
    <div className="form-section">
      <Form
        form={form}
        layout="vertical"
        initialValues={{ protocol: initialData.protocol }}
        onValuesChange={onChange}
        requiredMark={true}
      >
        <Form.Item required label="Select your communication channel" name="protocol">
          <Radio.Group onChange={(e) => form.resetFields([e.target.value])}>
            {ProtocolLabels.map(({ value, label }) => (
              <Radio.Button key={value} value={value}>
                {label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        {/**
         * LINK START
         */}
        {data.protocol === Protocol.LINK && (
          <Row>
            <Col lg={9} md={12} xs={24} className="form-col">
              <Form.Item
                name={[data.protocol, "url"]}
                label="URL"
                required
                tooltip={{
                  title: "The url, starting with http:// or https://",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input placeholder="http:// or https://" />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/**
         * LINK END
         */}

        {/**
         * EMAIL START
         */}
        {data.protocol === Protocol.EMAIL && (
          <>
            <Row>
              <Col lg={9} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "email"]}
                  label="Email Address"
                  required
                  tooltip={{
                    title: "The email where you want to send the email",
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Input placeholder="example@example.com" />
                </Form.Item>
              </Col>
              <Col lg={9} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "subject"]}
                  label="Subject"
                  requiredMark="optional"
                  tooltip={{
                    title: "The subject of your email",
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Input placeholder="Some optional subject" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={18} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "message"]}
                  label="Message"
                  requiredMark="optional"
                  tooltip={{
                    title: "The message you want to send",
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Input.TextArea placeholder="Some optional text" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {/**
         * EMAIL END
         */}

        {/**
         * TEXT START
         */}
        {data.protocol === Protocol.TEXT && (
          <Row>
            <Col md={12} xs={24} className="form-col">
              <Form.Item
                name={[data.protocol, "text"]}
                label="Text"
                required
                tooltip={{
                  title: "The text you want to convert to QR",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input.TextArea placeholder="Your message" />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/**
         * TEXT END
         */}

        {/**
         * CALL & SMS START
         */}
        {(data.protocol === Protocol.CALL ||
          data.protocol === Protocol.SMS) && (
          <>
            <Row>
              <Col md={12} xs={24} className="form-col">
                <Form.Item label="Phone Number" required>
                  <Input.Group compact>
                    <Form.Item name={[data.protocol, "country_code"]} noStyle>
                      <Select
                        className="country_code_selector"
                        showSearch={true}
                        placeholder="Country Code"
                        filterOption={(input, option) => {
                          return (
                            option?.title
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {CountryCodeList.map(({ name, dial_code }) => (
                          <Select.Option
                            title={`${name} (${dial_code})`}
                            key={dial_code}
                            value={dial_code}
                          >
                            {name} ({dial_code})
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={[data.protocol, "number"]} noStyle>
                      <Input placeholder="Number" />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                {data.protocol === Protocol.SMS && (
                  <Form.Item
                    name={[data.protocol, "message"]}
                    label="Message"
                    required
                    tooltip={{
                      title: "The message you want to send",
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <Input.TextArea placeholder="Some optional text" />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </>
        )}
        {/**
         * CALL & SMS END
         */}

        {/**
         * Bitcoin START
         */}
        {data.protocol === Protocol.BITCOIN && (
          <>
            <Row>
              <Col lg={9} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "address"]}
                  label="Address"
                  required
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={9} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "amount"]}
                  label="Amount"
                  required
                >
                  <Input placeholder="BTC Amount" addonAfter={"BTC"} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={9} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "item_name"]}
                  label="Item Name"
                  requiredMark="optional"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={9} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "message"]}
                  label="Message"
                  requiredMark="optional"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {/**
         * Bitcoin END
         */}

        {/**
         * PAYPAL START
         */}
        {data.protocol === Protocol.PAYPAL && (
          <>
            <Row>
              <Col lg={9} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "payment_type"]}
                  label="Payment Type"
                  required
                >
                  <Select>
                    {PaypalPaymentTypeLabel.map(({ label, value }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={9} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "email"]}
                  label="Email"
                  required
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={18} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "item_name"]}
                  label="Item Name"
                  requiredMark="optional"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "price"]}
                  label="Price"
                  required
                >
                  <Input
                    addonAfter={data?.[Protocol.PAYPAL]?.currency || "-"}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "currency"]}
                  label="Currency"
                  required
                >
                  <Select>
                    {CurrencyList.map(({ name, code }) => (
                      <Select.Option key={code} value={code}>
                        {name} ({code})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} md={12} xs={24} className="form-col">
                <Form.Item
                  name={[data.protocol, "shipping_charge"]}
                  label="Shipping"
                >
                  <Input
                    placeholder="0.0"
                    addonAfter={data?.[Protocol.PAYPAL]?.currency || "-"}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={12} xs={24} className="form-col">
                <Form.Item name={[data.protocol, "tax_rate"]} label="Tax Rate">
                  <Input placeholder="0.0" addonAfter={"%"} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {/**
         * PAYPAL END
         */}
      </Form>
    </div>
  );
}
