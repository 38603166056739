import { Col, Layout, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import "./App.scss";
import PageFooter from "./components/page-footer";
import PageHeader from "./components/page-header";
import QrDisplay from "./components/qr-display";
import QrForm from "./components/qr-form";

const { Content } = Layout;

function App() {
  const { lg, xl, xxl, md } = useBreakpoint();

  return (
    <Layout className={lg || xl || xxl ? "desktop" : md ? "tablet" : "phone"}>
      <PageHeader />
      <Content className="content">
        <Layout className="content-inner">
          <Row className="content-row">
            <Col xs={24} md={16} lg={16}>
              <QrForm />
            </Col>
            <Col xs={24} md={8} lg={8}>
              <QrDisplay />
            </Col>
          </Row>
        </Layout>
      </Content>
      <PageFooter />
    </Layout>
  );
}

export default App;
