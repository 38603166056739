const CurrencyList = [
  { name: "Australian Dollar", code: "AUD" },
  { name: "Brazilian real", code: "BRL" },
  { name: "Canadian Dollar", code: "CAD" },
  { name: "Chinese Renmenbi ", code: "CNY" },
  { name: "Czech Koruna", code: "Czk" },
  { name: "Danish krone", code: "DKK" },
  { name: "Euro", code: "EUR" },
  { name: "Hong Kong Dollar", code: "HKD" },
  { name: "Hungarian Forint", code: "HUF" },
  { name: "Israeli new shekel", code: "ILS" },
  { name: "Japanese yen", code: "JPY" },
  { name: "Malaysian ringgit", code: "MYR" },
  { name: "Mexican peso", code: "MXN" },
  { name: "New Taiwan dollar", code: "TWD" },
  { name: "New Zealand dollar", code: "NZD" },
  { name: "Norwegian krone", code: "NOK" },
  { name: "Philippine peso", code: "PHP" },
  { name: "Polish złoty", code: "PLN" },
  { name: "Pound sterling", code: "GBP" },
  { name: "Russian ruble", code: "RUB" },
  { name: "Singapore dollar", code: "SGD" },
  { name: "Swedish krona", code: "SEK" },
  { name: "Swiss franc", code: "CHF" },
  { name: "Thai baht", code: "THB" },
  { name: "US dollar", code: "USD" },
];

export default CurrencyList;
