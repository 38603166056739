import { Alert, Card, Dropdown, Menu } from "antd";
import QRCodeStyling, {
  ExtensionFunction,
  FileExtension,
} from "qr-code-styling";
import { useContext, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce/lib";
import { QrConfigContext } from "../context/qr-config";

const extension: ExtensionFunction = (svg, config) => {
  const transparent = !!(config.backgroundOptions as any).transparent;
  if (transparent) {
    svg
      .querySelector(`rect[clip-path*=clip-path-background-color]`)
      ?.setAttribute("fill-opacity", "0.0");
  }
};

export default function QrDisplay() {
  const { config } = useContext(QrConfigContext);
  const instance = new QRCodeStyling(config);
  instance.applyExtension(extension);
  const qrCode = useRef<QRCodeStyling>(instance);
  const ref = useRef<HTMLDivElement>(null);
  const outputAreaRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(1);

  useEffect(() => {
    if (ref.current) {
      qrCode.current.append(ref.current);
    }
  }, [ref]);

  useEffect(() => {}, [qrCode]);

  useEffect(() => {
    qrCode.current?.update(config);
  }, [qrCode, config]);

  const calculateScale = () => {
    try {
      if (!config.data) {
        throw new Error("No QR code");
      }
      const rect = outputAreaRef.current?.getBoundingClientRect();
      if (rect) {
        const { width } = rect;
        if (width >= config.width!) {
          throw new Error("No need to scale");
        }
        const scale = width / config.width!;
        setScale(scale);
      }
    } catch (e) {
      setScale(1);
    }
  };

  useEffect(() => {
    calculateScale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.width, config.height, config.data]);
  const _callback = useDebouncedCallback(calculateScale, 100);
  useEffect(() => {
    window.addEventListener("resize", _callback);
    return () => {
      window.removeEventListener("resize", _callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `scale(${scale})`;
    }
  }, [scale]);

  const download = (extension: FileExtension = "svg") => {
    qrCode.current?.download({
      extension,
    });
  };
  function handleMenuClick({ key }: any) {
    download(key as FileExtension);
  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="svg">as SVG</Menu.Item>
      <Menu.Item key="png">as PNG</Menu.Item>
      {!(config.backgroundOptions as any).transparent && (
        <Menu.Item key="jpg">as JPG</Menu.Item>
      )}
      <Menu.Item key="webp">as WEBP</Menu.Item>
    </Menu>
  );

  return (
    <div className="qr-container">
      <Card
        actions={
          config.data
            ? [
                <Dropdown.Button
                  placement="topCenter"
                  onClick={() => download()}
                  overlay={menu}
                >
                  Download
                </Dropdown.Button>,
              ]
            : []
        }
      >
        <div
          className={`qr-output-area ${config.data ? "has-data" : ""}`}
          ref={outputAreaRef}
          style={{
            height: config.height && config.data ? config.height * scale : 300,
          }}
        >
          <div ref={ref} className={`canvas-wrapper`} />
          {!config.data && (
            <Alert type="info" message="No qr code data" showIcon />
          )}
        </div>
      </Card>
    </div>
  );
}
