import { Form, InputNumber, Switch } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import { useContext } from "react";
import { QrConfigContext } from "../context/qr-config";
import LogoPicker from "./logo-picker";

export default function ImageConfig() {
  const { config, updateConfig } = useContext(QrConfigContext);
  const changeHandler = (path: string) => (value: valueType) =>
    updateConfig(path, value);
  return (
    <Form labelCol={{ xs: 6, md: 4, lg: 3 }} wrapperCol={{ xs: 24, md: 6 }}>
      <Form.Item label="Image">
        <LogoPicker
          src={config.image}
          onChange={(src) => updateConfig("image", src)}
        />
      </Form.Item>
      {config.image && (
        <>
          <Form.Item label="Hide Background Dots">
            <Switch
              checked={config.imageOptions?.hideBackgroundDots}
              onChange={(value) =>
                updateConfig("imageOptions.hideBackgroundDots", value)
              }
            />
          </Form.Item>
          <Form.Item label="Image Size" extra="maximum 0.5 recommended">
            <InputNumber
              value={config.imageOptions?.imageSize}
              min={0}
              max={1}
              step={0.1}
              onChange={changeHandler("imageOptions.imageSize")}
            />
          </Form.Item>
          <Form.Item label="Margin">
            <InputNumber
              value={config.imageOptions?.margin}
              min={0}
              onChange={changeHandler("imageOptions.margin")}
            />
          </Form.Item>
        </>
      )}
    </Form>
  );
}
