import { Options } from "qr-code-styling";
import { createContext, useState } from "react";
import { set } from "tiny-immutable-set";

interface QrConfig {
  config: Options;
  updateConfig: (path: string, value: any) => void;
}

const initialConfig: Options = {
  type: "svg",
  width: 300,
  height: 300,
  margin: 0,
  dotsOptions: {
    type: "square",
    color: "#000000",
  },
  cornersSquareOptions: {
    type: "square",
    color: "#000000",
  },
  cornersDotOptions: {
    type: "square",
    color: "#000000",
  },
  backgroundOptions: {
    color: "#ffffff",
  },
  imageOptions: {
    imageSize: 0.4,
    hideBackgroundDots: true,
    margin: 0,
  },
};

export const QrConfigContext = createContext<QrConfig>({} as QrConfig);

export default function QrConfigProvider({ children }: any) {
  const [config, setConfig] = useState<Options>({ ...initialConfig });
  const updateConfig = (path: string, value: any) => {
    setConfig((config) => set(config, path, value));
  };

  return (
    <QrConfigContext.Provider value={{ updateConfig, config }}>
      {children}
    </QrConfigContext.Provider>
  );
}
