import { Form, Select } from "antd";
import { useContext } from "react";
import { QrConfigContext } from "../context/qr-config";
import ColorPicker from "./color-picker";

type Prop = {
  styles?: { value: string; label: string }[];
  property:
    | "dotsOptions"
    | "cornersSquareOptions"
    | "cornersDotOptions"
    | "backgroundOptions";
};

export default function DotConfig({ styles, property }: Prop) {
  const { config, updateConfig } = useContext(QrConfigContext);
  const updateDotConfig = (value: any) => {
    const data = { ...value };
    if (Array.isArray(styles)) {
      data.type = (config[property] as any).type;
    }
    updateConfig(property, data);
  };
  return (
    <Form labelCol={{ xs: 6, md: 4, lg: 3 }} wrapperCol={{ xs: 24, md: 6 }}>
      {Array.isArray(styles) && (
        <Form.Item label="Style">
          <Select
            value={(config[property] as any)?.type}
            onChange={(value) => updateConfig(`${property}.type`, value)}
          >
            {styles.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item label="Color">
        <ColorPicker value={config[property]} onChange={updateDotConfig} />
      </Form.Item>
    </Form>
  );
}
