import { Col, Form, Input, InputNumber, Radio, Row, Typography } from "antd";
import { Gradient } from "qr-code-styling";
import React, { useEffect, useState } from "react";
import { set } from "tiny-immutable-set";
import { useDebouncedCallback } from "use-debounce/lib";

interface Value {
  color?: string;
  gradient?: Gradient;
}

interface Prop {
  value?: Value;
  onChange: (data: Value) => void;
}

const initialFormValue: any = {
  color__mode: "color",
  color: "#000000",
  gradient: {
    type: "linear",
    rotation: 0,
    colorStops: [
      { color: "#000000", offset: 0 },
      { color: "#000000", offset: 1 },
    ],
  } as Gradient,
};

export default function ColorPicker({ value, onChange }: Prop) {
  const [state, setState] = useState<any>({
    ...initialFormValue,
    gradient: undefined,
    ...value,
  });

  useEffect(() => {
    const { color__mode, ...rest } = state;
    onChange(rest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const setValue = useDebouncedCallback((path: string, value: any) => {
    setState((state: any) => set(state, path, value));
  }, 100);
  const onColorModeChange = (color__mode: "color" | "gradient") => {
    let color: string, gradient: Gradient;
    if (color__mode === "color") {
      color = initialFormValue.color;
    }
    if (color__mode === "gradient") {
      gradient = initialFormValue.gradient;
    }
    setState((state: any) => ({ ...state, color, gradient, color__mode }));
  };
  return (
    <>
      <Form.Item>
        <Radio.Group
          value={state.color__mode}
          onChange={(e) => onColorModeChange(e.target.value)}
        >
          <Radio.Button value="color">Color</Radio.Button>
          <Radio.Button value="gradient">Gradient</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {state.color__mode === "color" && (
        <Form.Item wrapperCol={{ span: 6 }}>
          <Typography.Text strong>Color</Typography.Text>
          <Input
            type="color"
            value={state.color || "#ffffff"}
            onChange={(e) => setValue("color", e.target.value)}
          />
        </Form.Item>
      )}
      {state.color__mode === "gradient" && (
        <>
          <Row gutter={4}>
            <Col md="6" xs="12" lg="3">
              <div>
                <Typography.Text strong>Type</Typography.Text>
              </div>
              <Radio.Group
                value={state.gradient?.type}
                onChange={(e) => setValue("gradient.type", e.target.value)}
              >
                <Radio.Button value="linear">Linear</Radio.Button>
                <Radio.Button value="radial">Radial</Radio.Button>
              </Radio.Group>
            </Col>
            <Col md="6" xs="12" lg="3">
              <div>
                <Typography.Text strong>Rotation</Typography.Text>
              </div>
              <InputNumber
                type="number"
                min="0"
                max="180"
                value={state.gradient?.rotation || 0}
                onChange={(e) => setValue("gradient.rotation", +e)}
              />
              <div>
                <Typography.Text type="secondary">0 to 180</Typography.Text>
              </div>
            </Col>
            <Col md="6" xs="12" lg="3">
              <Typography.Text strong>Color 1</Typography.Text>
              <Input
                type="color"
                value={state.gradient?.colorStops?.[0]?.color}
                onChange={(e) =>
                  setValue("gradient.colorStops[0]", {
                    color: e.target.value,
                    offset: 0,
                  })
                }
              />
            </Col>
            <Col md="6" xs="12" lg="3">
              <Typography.Text strong>Color 2</Typography.Text>
              <Input
                type="color"
                value={state.gradient?.colorStops?.[1]?.color || ""}
                onChange={(e) =>
                  setValue("gradient.colorStops[1]", {
                    color: e.target.value,
                    offset: 1,
                  })
                }
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
