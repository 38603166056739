import parse from "libphonenumber-js";
import { Protocol } from "../data/protocol";

function serialize(obj: any, glue: string = "&") {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join(glue);
}

function joinQuery(url: string, query?: string) {
  if (!url) {
    return "";
  }
  if (query) {
    url = `${url}?${query}`;
  }
  return url;
}

function getActualPhone(number?: string, country_code?: string) {
  if (!number) {
    return "";
  }
  if (country_code) {
    number = `${country_code}${number}`;
  }
  const result = parse(number);
  return result?.isValid() ? result.number : number;
}

function dataForLink(url: string = "") {
  if (!url) {
    return "";
  }
  if (!(url.startsWith("http://") || url.startsWith("https://"))) {
    url = `http://${url}`;
  }
  return url;
}

function dataForEmail(address?: string, subject?: string, body?: string) {
  if (!address) return "";
  return joinQuery(`mailto:${address}`, serialize({ subject, body }));
}

function dataForText(text?: string) {
  if (!text) return "";
  return `${text}`;
}

function dataForCall(number?: string, country_code?: string) {
  if (!number) {
    return "";
  }
  return `tel:${getActualPhone(number, country_code)}`;
}

function dataForSMS(number?: string, body?: string, country_code?: string) {
  if (!(number && body)) {
    return "";
  }
  body = serialize({ body });
  const url = `sms:${getActualPhone(number, country_code)}`;
  return joinQuery(url, body);
}

function dataForBitCoin(
  address?: string,
  amount?: string | number,
  label?: string,
  message?: string
) {
  if (!(address && typeof amount !== "undefined")) {
    return "";
  }
  const qs = serialize({ amount, label, message });
  const url = `bitcoin:${address}`;
  return joinQuery(url, qs);
}

// https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=theobaidur%40gmail.com&item_name=test&amount=12&currency_code=EUR&shipping=12&tax_rate=12&button_subtype=services&bn=PP-BuyNowBF%3Abtn_buynow_LG.gif%3ANonHostedGuest&lc=US&no_note=0
const bnMap: any = {
  _xclick: "PP-BuyNowBF:btn_buynow_LG.gif:NonHostedGuest",
  _cart: "PP-ShopCartBF:btn_cart_LG.gif:NonHostedGuest",
  _donations: "PP-DonationsBF:btn_donate_LG.gif:NonHostedGuest",
};

const subTypeMap: any = {
  _xclick: "services",
  _cart: "products",
};
function dataForPaypal(
  cmd?: string,
  business?: string,
  amount?: string | number,
  currency_code?: string,
  item_name?: string,
  shipping?: number | string,
  tax_rate?: number | string
) {
  if (!(cmd && typeof amount !== "undefined" && business && currency_code)) {
    return "";
  }
  const queries = {
    cmd,
    business,
    item_name,
    amount,
    currency_code,
    shipping,
    tax_rate,
    button_subtype: cmd ? subTypeMap[cmd] : undefined,
    bn: cmd ? bnMap[cmd] : bnMap._cart,
    lc: "US",
    no_note: 0,
  };
  const qs = serialize(queries);
  const url = "https://www.paypal.com/cgi-bin/webscr";
  return joinQuery(url, qs);
}

const DataGenerator = {
  [Protocol.LINK]: dataForLink,
  [Protocol.EMAIL]: dataForEmail,
  [Protocol.TEXT]: dataForText,
  [Protocol.CALL]: dataForCall,
  [Protocol.SMS]: dataForSMS,
  [Protocol.BITCOIN]: dataForBitCoin,
  [Protocol.PAYPAL]: dataForPaypal,
};

export default DataGenerator;
