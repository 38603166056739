import { Alert, Collapse, Divider } from "antd";
import { useContext } from "react";
import { QrConfigContext } from "../context/qr-config";
import BackgroundConfig from "./qr-background-config";
import QrContent from "./qr-content";
import DimensionConfig from "./qr-dimension-config";
import DotConfig from "./qr-dot-config";
import ImageConfig from "./qr-image-config";

const dotTypes: { value: string; label: string }[] = [
  { label: "Square", value: "square" },
  { label: "Dots", value: "dots" },
  { label: "Rounded", value: "rounded" },
  { label: "Extra Rounded", value: "extra-rounded" },
  { label: "Classy", value: "classy" },
  { label: "Classy Rounded", value: "classy-rounded" },
];

const cornerSquareTypes: typeof dotTypes = [
  { label: "Square", value: "square" },
  { label: "Dots", value: "dots" },
  { label: "Extra Rounded", value: "extra-rounded" },
];

const cornerDotTypes: typeof dotTypes = [
  { label: "Square", value: "square" },
  { label: "Dots", value: "dots" },
];

export default function QrForm() {
  const { config, updateConfig } = useContext(QrConfigContext);
  const setData = (data?: string) => {
    updateConfig("data", data);
  };
  return (
    <div className="config-area">
      <QrContent onChange={setData} />
      <div className="form-section">
        <Divider orientation="left">Configurations</Divider>
        {config.data && (
          <Collapse defaultActiveKey={1}>
            <Collapse.Panel key={1} header="Dimensions">
              <DimensionConfig />
            </Collapse.Panel>
            <Collapse.Panel key={2} header="Dots">
              <DotConfig property="dotsOptions" styles={dotTypes} />
            </Collapse.Panel>
            <Collapse.Panel key={3} header="Corner Square">
              <DotConfig
                property="cornersSquareOptions"
                styles={cornerSquareTypes}
              />
            </Collapse.Panel>
            <Collapse.Panel key={4} header="Corner Dots">
              <DotConfig property="cornersDotOptions" styles={cornerDotTypes} />
            </Collapse.Panel>
            <Collapse.Panel key={5} header="Background">
              <BackgroundConfig />
            </Collapse.Panel>
            <Collapse.Panel key={6} header="Image / Logo">
              <ImageConfig />
            </Collapse.Panel>
          </Collapse>
        )}
        {!config.data && (
          <Alert
            type="info"
            message="Provide data to see configuration options"
            showIcon
          />
        )}
      </div>
    </div>
  );
}
