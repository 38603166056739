import { Layout } from "antd";
import logo from "../assets/logo.png";

export default function PageHeader() {
  return (
    <Layout.Header className="header">
      <div className="logo">
        <img src={logo} alt="ziprouter" />
      </div>
    </Layout.Header>
  );
}
