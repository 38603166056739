import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { useEffect, useState } from "react";

type Prop = {
  src?: string;
  onChange: (src?: string) => void;
};
export default function LogoPicker({ src, onChange }: Prop) {
  const [fileList, setFileList] = useState<UploadFile[]>(
    src
      ? [
          {
            uid: `${+new Date()}-${Math.random()}`,
            name: "",
            url: src,
            status: "done",
          },
        ]
      : []
  );
  const uploadFile = (file: File) => {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result as string));
      reader.readAsDataURL(file);
    });
  };
  const upload = async ({ file }: UploadChangeParam) => {
    setFileList([
      {
        status: "uploading",
        name: file.name,
        uid: file.uid,
      },
    ]);
    const url = await uploadFile(file as any);
    setFileList([
      {
        status: "done",
        name: file.name,
        uid: file.uid,
        url,
      },
    ]);
  };
  useEffect(() => {
    const src = fileList[0]?.url;
    onChange(src);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);
  return (
    <Upload
      listType="picture"
      maxCount={1}
      beforeUpload={() => false}
      fileList={fileList}
      onChange={upload}
      accept="image/*"
      onPreview={() => {}}
      onRemove={() => {
        setFileList([]);
        return false;
      }}
    >
      <Button icon={<UploadOutlined />}>Select</Button>
    </Upload>
  );
}
