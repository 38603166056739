import { Form, InputNumber } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import { useContext } from "react";
import { QrConfigContext } from "../context/qr-config";

/**
 * for some reason, margin is only affected by multiple of 13
 */
const marginMultipleBy = 13;

export default function DimensionConfig() {
  const { config, updateConfig } = useContext(QrConfigContext);
  const changeHandler =
    (path: string, multiplyBy = 1) =>
    (value: valueType) =>
      updateConfig(path, +value * multiplyBy);
  return (
    <Form labelCol={{ xs: 6, md: 4, lg: 3 }} wrapperCol={{ xs: 24, md: 6 }}>
      <Form.Item label="Width">
        <InputNumber
          type="number"
          min={150}
          value={config.width}
          onChange={changeHandler("width")}
        />
      </Form.Item>
      <Form.Item label="Height">
        <InputNumber
          value={config.height}
          min={150}
          onChange={changeHandler("height")}
        />
      </Form.Item>
      <Form.Item label="Margin">
        <InputNumber
          value={Math.floor(config.margin! / marginMultipleBy)}
          min={0}
          onChange={changeHandler("margin", marginMultipleBy)}
        />
      </Form.Item>
    </Form>
  );
}
